import styled from 'styled-components'
import BackgroundImage from 'src/assets/images/background-account-signup.jpg'
import Providers from 'src/providers/index'
import AppRouter from 'src/AppRouter'

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  background-image: url(${BackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const App = () => {
  return (
    <Providers>
      <Layout>
        <AppRouter />
      </Layout>
    </Providers>
  )
}

export default App
