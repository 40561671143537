import React, { useMemo, useLayoutEffect } from 'react'

import { Route, Routes } from 'react-router-dom'
import FirstPage from 'src/modules/CreateAccount/FormPages/FirstPage'
import SecondPage from 'src/modules/CreateAccount/FormPages/SecondPage'
import ThirdPage from 'src/modules/CreateAccount/FormPages/ThirdPage'

import { useParams } from 'src/providers/ParamProvider'

import { FormProvider, useForm } from 'react-hook-form'
import { FormValues } from 'src/providers'
import countries, { CountryItemShape } from 'src/utils/countries'
import { CountryEnum } from 'src/generated/graphql'

export enum CreateAccountPaths {
  First = '/create-account/welcome',
  Second = '/create-account/almost-there',
  Third = '/create-account/check-your-mailbox',
}

interface Props {}

const AppRouter: React.FC<Props> = () => {
  const { countrySearchParams } = useParams() || {}

  const defaultCountry = useMemo(() => {
    return countries.find((country: CountryItemShape) => {
      return country.flag.toString() === countrySearchParams
    })?.value
  }, [countrySearchParams])

  const defaultValues = useMemo(() => {
    return {
      isOptInCorrespondence: defaultCountry !== CountryEnum.Germany,
      country: (defaultCountry as CountryEnum) || CountryEnum.UnitedStates,
    }
  }, [defaultCountry])

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
  })

  const { reset } = methods

  // Ensure that default form values are assigned before first render.
  useLayoutEffect(() => {
    return reset(defaultValues)
  }, [defaultValues, reset])

  return (
    <FormProvider {...methods}>
      <Routes>
        <Route path="*" element={<FirstPage />} />
        <Route path={CreateAccountPaths.First} element={<FirstPage />} />
        <Route path={CreateAccountPaths.Second} element={<SecondPage />} />
        <Route path={CreateAccountPaths.Third} element={<ThirdPage />} />
      </Routes>
    </FormProvider>
  )
}

export default AppRouter
