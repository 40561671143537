import Illustration, {
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'
import { BoxProps } from '@mui/material'

interface CheckBoxProps extends BoxProps {
  checked: boolean
  handleClick: () => void
}

const Checkbox = ({ checked, handleClick, ...props }: CheckBoxProps) => {
  return (
    <Illustration
      onClick={handleClick}
      type={checked ? UtilityIcon.GoldTick : UtilityIcon.EmptyCircle}
      width={24}
      {...props}
    />
  )
}

export default Checkbox
