import React from 'react'
import styled, { css } from 'styled-components'
import { Typography } from '@mui/material'
import Illustration, {
  UtilityIcon,
} from 'src/components/dataDisplay/Illustration'
import { WarningContainer } from 'src/modules/CreateAccount/FormContent.styles'

const ErrorText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.brand.red};
  `
)

interface Props {
  message: string
}

const ErrorDisplay: React.FC<Props> = ({ message }) => {
  return (
    <WarningContainer>
      <Illustration type={UtilityIcon.Error} mr={1} width={16} height={16} />
      <ErrorText>{message}</ErrorText>
    </WarningContainer>
  )
}

export default ErrorDisplay
