import React from 'react'
import styled, { css } from 'styled-components'
import { Box, Typography } from '@mui/material/'
import Illustration, { LogoIcon } from 'src/components/dataDisplay/Illustration'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from 'src/components/button/Button'
import { useTranslation } from 'react-i18next'

const Heading = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(5)};
    margin-bottom: ${theme.spacing(4)};
    font-size: ${theme.typography.pxToRem(18)};

    ${theme.breakpoints.down('md')} {
      font-size: ${theme.typography.pxToRem(14)};
      margin: 0;
    }
  `
)

const Wrapper = styled(Box)<{ $isOpen?: boolean }>(
  ({ theme, $isOpen }) => css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      flex-direction: column;
      overflow: ${$isOpen ? 'visible' : 'hidden'};
      height: fit-content;
    }
  `
)

const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(0.75)};
`

const DesktopHeaderContent = styled(Box)(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `
)

const MobileHeaderContent = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    height: fit-content;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
)

const TranslationContainer = styled(Box)<{ $isOpen?: boolean }>(
  ({ theme, $isOpen }) => css`
    ${theme.breakpoints.down('md')} {
      display: ${$isOpen ? 'flex' : 'none'};
      flex-direction: column;
      padding-top: ${theme.spacing(3)};
      height: 100%;
    }
  `
)
const Chevron = styled(KeyboardArrowDownIcon)<{ $isOpen?: boolean }>(
  ({ $isOpen }) => css`
    transform: ${$isOpen && `rotate(180deg)`};
    transition: transform 1s;
  `
)

interface Props {
  toggleIsOpen?: () => void
  isOpen?: boolean
}
const SidebarContent: React.FC<Props> = ({ toggleIsOpen, isOpen }) => {
  const { t } = useTranslation()
  return (
    <Wrapper $isOpen={isOpen}>
      <DesktopHeaderContent>
        <Illustration type={LogoIcon.LesMillsBlack} width={90} />
        <Heading> {t('AccountRegistration.sidebar.title')}</Heading>
      </DesktopHeaderContent>
      <MobileHeaderContent>
        <Illustration type={LogoIcon.LesMillsBlack} width={90} />
        <StyledButton
          variant="text"
          endIcon={<Chevron $isOpen={isOpen} />}
          onClick={toggleIsOpen}
        >
          <Heading> {t('AccountRegistration.sidebar.title')}</Heading>
        </StyledButton>
      </MobileHeaderContent>
      <TranslationContainer $isOpen={isOpen}>
        <Box mb={4}>
          <Typography variant="body2">
            {t('AccountRegistration.sidebar.firstSectionTitle')}
          </Typography>
          <Typography variant="body1">
            {t('AccountRegistration.sidebar.firstSectionDescription')}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body2">
            {t('AccountRegistration.sidebar.secondSectionTitle')}
          </Typography>
          <Typography variant="body1">
            {t('AccountRegistration.sidebar.secondSectionDescription')}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body2">
            {t('AccountRegistration.sidebar.thirdSectionTitle')}
          </Typography>
          <Typography variant="body1">
            {t('AccountRegistration.sidebar.thirdSectionDescription')}
          </Typography>
        </Box>
      </TranslationContainer>
    </Wrapper>
  )
}

export default SidebarContent
