import React from 'react'
import styled, { css } from 'styled-components'
import { Box, Typography } from '@mui/material/'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/button/Button'
import Divider from 'src/components/layout/Divider'
import { useAuth0 } from '@auth0/auth0-react'

export const FooterContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const LoginButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.brand.gold};
  `
)

const FormFooter = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
    })
  }

  return (
    <FooterContainer>
      <Divider my={3} />
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {t('AccountRegistration.footer.accountExists')}
        </Typography>
        <LoginButton variant="text" onClick={handleLogin}>
          {t('AccountRegistration.footer.login')}
        </LoginButton>
      </Box>
    </FooterContainer>
  )
}

export default FormFooter
