import React from 'react'
import { Box, BoxProps } from '@mui/material/'

import Img from 'src/components/dataDisplay/Img'

// Logo Icons
import lesMillsWhite from 'src/assets/images/icons/lesMillsWhite.svg'
import lesMillsBlack from 'src/assets/images/icons/lesMillsBlack.svg'
// Utility Icons
import success from 'src/assets/images/icons/success.svg'
import error from 'src/assets/images/icons/error.svg'
import goldentick from 'src/assets/images/icons/goldentick.svg'
import emptyCircle from 'src/assets/images/icons/emptyCircle.svg'

export enum LogoIcon {
  LesMillsWhite = 'LES_MILLS_WHITE',
  LesMillsBlack = 'LES_MILLS_BLACK',
}

export enum UtilityIcon {
  Error = 'ERROR',
  Success = 'Success',
  EmptyCircle = 'EMPTY_CIRCLE',
  GoldTick = 'GOLD_TICK',
}

export type IconTypes = LogoIcon | UtilityIcon

export interface IllustrationProps extends BoxProps {
  type: IconTypes
}

interface IllustrationImg {
  src: string
  alt: string
}

const illustrations: {
  [k in IconTypes]: IllustrationImg
} = {
  // Logo Icons
  [LogoIcon.LesMillsWhite]: {
    src: lesMillsWhite,
    alt: 'Les Mills Digital Hub',
  },
  [LogoIcon.LesMillsBlack]: {
    src: lesMillsBlack,
    alt: 'Les Mills Digital Hub',
  },
  // Utility Icons
  [UtilityIcon.Error]: { src: error, alt: 'Error' },
  [UtilityIcon.Success]: { src: success, alt: 'Success' },
  [UtilityIcon.GoldTick]: { src: goldentick, alt: 'Tick' },
  [UtilityIcon.EmptyCircle]: { src: emptyCircle, alt: 'Empty circle' },
}

export const Illustration: React.FC<IllustrationProps> = ({
  type,
  ...props
}) => (
  <Box {...props}>
    <Img {...illustrations[type]} />
  </Box>
)

export default Illustration
