import React, { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as ApolloProviderBase,
} from '@apollo/client'
import { useAuth0, IdToken } from '@auth0/auth0-react'

import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: import.meta.env.REACT_APP_API_URL,
})

interface HeaderShape {
  'X-API-KEY': string | undefined
  Authorization?: string | undefined
}

const ApolloProvider: React.FC = ({ children }) => {
  const { getIdTokenClaims } = useAuth0()
  const authLink = useMemo(
    () =>
      setContext(async ({ operationName }) => {
        const token: IdToken | undefined = await getIdTokenClaims()
        const headers: HeaderShape = {
          'X-API-KEY': import.meta.env.REACT_APP_X_API_KEY,
        }
        if (operationName === 'getUserSession') {
          headers.Authorization = token?.__raw
        }
        return {
          headers,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIdTokenClaims]
  )

  const link = useMemo(() => authLink.concat(httpLink), [authLink])

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        uri: import.meta.env.REACT_APP_API_URL,
        link,
        defaultOptions: {
          mutate: {
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
          watchQuery: {
            errorPolicy: 'all',
          },
        },
      }),
    [link]
  )

  return <ApolloProviderBase client={client}>{children}</ApolloProviderBase>
}

export default ApolloProvider
