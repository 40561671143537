import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'

import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import GlobalStyles from 'src/styles/GlobalStyles'
import theme from 'src/styles/theme'
import ApolloProvider from 'src/providers/ApolloProvider'
import I18nProvider from 'src/providers/I18nProvider'
import RecaptchaProvider from 'src/providers/RecaptchaProvider'
import AnalyticsProvider from 'src/providers/AnalyticsProvider'
import { ParamProvider } from 'src/providers/ParamProvider'
import { CountryEnum, StatesEnum } from 'src/generated/graphql'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'

export interface FormValues {
  firstName: string
  lastName: string
  email: string
  country: CountryEnum
  state?: StatesEnum
  countryCode: string
  phoneNumber: string
  dateOfBirth?: string
  hasQualification: boolean
  isOptInCorrespondence: boolean
  recaptchaToken: string
}

const Providers: React.FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={import.meta.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID!}
      useRefreshTokens
    >
      <RecaptchaProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <I18nProvider>
            <ApolloProvider>
              <AnalyticsProvider>
                <MuiThemeProvider theme={theme}>
                  <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                      <BrowserRouter>
                        <ParamProvider>
                          <GlobalStyles />
                          <CssBaseline />
                          {children}
                        </ParamProvider>
                      </BrowserRouter>
                    </StyledEngineProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </AnalyticsProvider>
            </ApolloProvider>
          </I18nProvider>
        </LocalizationProvider>
      </RecaptchaProvider>
    </Auth0Provider>
  )
}

export default Providers
