import styled, { css } from 'styled-components'
import { DateField } from '@mui/x-date-pickers/DateField'

const DateInput = styled(DateField)<{
  mr?: number
  ml?: number
}>(
  ({ theme, ml, mr }) => css`
    font-size: ${theme.typography.pxToRem(14)};

    ${theme.breakpoints.up('md')} {
      ${ml !== undefined ? `margin-left: ${theme.spacing(ml)};` : ''}
      ${mr !== undefined ? `margin-right: ${theme.spacing(mr)};` : ''}
    }

    .MuiInputAdornment-positionStart {
      margin-right: 0px;
    }

    .MuiFormHelperText-root {
      text-align: right;
      letter-spacing: 0;

      &.Mui-focused {
        border: 0 none;
      }
    }
  `
)

export default DateInput
