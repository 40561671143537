import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import Illustration from 'src/components/dataDisplay/Illustration'
import Button from 'src/components/button/Button'

export const Container = styled(Box)(
  ({ theme }) => css`
    margin: auto;
    width: 520px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: ${theme.spacing(5)};
    background-color: ${theme.palette.brand.white};

    ${theme.breakpoints.down('md')} {
      width: 100%;
      height: 100vh;
      border-radius: 0px;
      padding: ${theme.spacing(2)};
    }
  `
)
export const FooterContent = styled(Box)(
  ({ theme }) => css`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.down('md')} {
      margin-top: ${theme.spacing(3)};
    }
  `
)
export const LinkContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

export const StyledIllustration = styled(Illustration)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(6)};
    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.spacing(5)};
    }
  `
)
export const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.brand.gold};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(1, 0)};
    }
  `
)
