import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { Typography } from '@mui/material'
import { LogoIcon } from 'src/components/dataDisplay/Illustration'
import Divider from 'src/components/layout/Divider'
import { CreateAccountPaths } from 'src/AppRouter'
import { useResendPasswordResetEmailMutation } from 'src/generated/hooks'

import Toast, { ToastTypeEnum } from 'src/components/feedback/Toast'
import useToast from 'src/hooks/useToast'
import { isErrorResult } from 'src/utils/typeGuards'
import useAnalytics from 'src/hooks/useAnalytics'
import { LocalAnalyticsEvent } from 'src/providers/AnalyticsProvider'
import {
  Container,
  StyledIllustration,
  FooterContent,
  LinkContainer,
  StyledButton,
} from 'src/modules/CreateAccount/CreatePassword.styles'

interface Props {}

const CreatePassword: React.FC<Props> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onFirstPage = () => navigate(CreateAccountPaths.First)
  const { showToast, hideToast, toastState, isToastOpen } = useToast()
  const analytics = useAnalytics()

  const email = sessionStorage.getItem('email')
  const [rerequestEmail, { loading }] = useResendPasswordResetEmailMutation()

  const handleRequestEmail = async () => {
    try {
      const response = await rerequestEmail({
        variables: { input: { email: email! } },
      })
      if (
        !response.data ||
        isErrorResult(response?.data?.resendPasswordResetEmail)
      ) {
        throw new Error(
          t('AccountRegistration.formContent.feedback.errorToast')
        )
      }
      showToast({
        message: t('AccountRegistration.formContent.feedback.resendSuccess'),
        variant: ToastTypeEnum.SUCCESS,
      })
    } catch (err) {
      showToast({
        message: t('AccountRegistration.formContent.feedback.errorToast'),
        variant: ToastTypeEnum.ERROR,
      })
    }
  }

  useEffect(() => {
    analytics.sendPageView(LocalAnalyticsEvent.ThirdPageView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!email) {
      return navigate(CreateAccountPaths.First)
    }
  }, [email, navigate])

  return (
    <>
      <Toast
        isOpen={isToastOpen}
        handleClose={hideToast}
        message={toastState?.message}
        variant={toastState?.variant}
      />
      <Container>
        <StyledIllustration type={LogoIcon.LesMillsBlack} />

        <Typography variant="h6" gutterBottom>
          {t('AccountRegistration.createPassword.title')}
        </Typography>
        <Typography variant="body1">
          <Trans
            i18nKey={'AccountRegistration.createPassword.description'}
            components={[<b />]}
            values={{ email: email }}
          />
        </Typography>
        <FooterContent>
          <Divider mb={3} mt={3} />
          <LinkContainer mb={1}>
            <Typography variant="body1">
              {t('AccountRegistration.createPassword.notReceived')}
            </Typography>
            <StyledButton
              variant="text"
              loading={loading}
              onClick={handleRequestEmail}
            >
              <Typography variant="body1">
                {t('AccountRegistration.createPassword.resendLink')}
              </Typography>
            </StyledButton>
          </LinkContainer>
          <LinkContainer>
            <Typography variant="body1">
              {t('AccountRegistration.createPassword.wrongAddress')}
            </Typography>
            <StyledButton variant="text" onClick={onFirstPage}>
              <Typography variant="body1">
                {t('AccountRegistration.createPassword.backToCreate')}
              </Typography>
            </StyledButton>
          </LinkContainer>
        </FooterContent>
      </Container>
    </>
  )
}

export default CreatePassword
