import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'

const TextInput = styled(TextField)<{
  mr?: number
  ml?: number
}>(
  ({ theme, ml, mr }) => css`
    width: 100%;
    font-size: ${theme.typography.pxToRem(14)};
    margin-bottom: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      ${ml !== undefined ? `margin-left: ${theme.spacing(ml)};` : ''}
      ${mr !== undefined ? `margin-right: ${theme.spacing(mr)};` : ''}
    }

    .MuiInputAdornment-positionStart {
      margin-right: 0px;
    }

    .MuiFormHelperText-root {
      text-align: right;
      letter-spacing: 0;
      &.Mui-focused {
        border: 0 none;
      }
    }
  `
)

export default TextInput
