/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react'
import { FlagIcon } from 'react-flag-kit'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Typography,
  InputAdornment,
  ListItemIcon,
  CircularProgress,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material/'
import {
  CalendarToday,
  InfoOutlined,
  KeyboardArrowDown,
  MailOutline,
  PermIdentity,
} from '@mui/icons-material/'
import {
  SubmitHandler,
  Controller,
  useFormContext,
  FieldValues,
} from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import useRedirect from 'src/hooks/useRedirect'

import TextInput from 'src/components/inputs/TextInput'
import Checkbox from 'src/components/inputs/Checkbox'
import DateInput from 'src/components/inputs/DateInput'
import ErrorDisplay from 'src/components/feedback/ErrorDisplay'
import FormFooter from 'src/modules/CreateAccount/FormFooter'
import Button from 'src/components/button/Button'
import SidebarContent from 'src/modules/CreateAccount/SidebarContent'

import countries, { CountryItemShape } from 'src/utils/countries'
import states, { StateItemShape } from 'src/utils/states'
import { CreateAccountPaths } from 'src/AppRouter'
import { CountryEnum, StatesEnum } from 'src/generated/graphql'
import { FormValues } from 'src/providers'
import useAnalytics from 'src/hooks/useAnalytics'

import {
  LocalAnalyticsEvent,
  EventCategory,
  EventAction,
} from 'src/providers/AnalyticsProvider'
import {
  NavigationContainer,
  BackChevron,
  Heading,
  NameInputContainer,
  StyledSelect,
  MenuItem,
  StyledMenuItem,
  GoldText,
  MarketingContainer,
  TextWithStyledLinks,
  StyledButton,
  Container,
  FormContainer,
  SidebarContainer,
  DateOfBirthInputContainer,
  DateOfBirthInputStyle,
  CheckboxContainer,
  QualificationContainer,
} from 'src/modules/CreateAccount/FormContent.styles'
import moment from 'moment'

const FirstPage: React.FC = () => {
  const navigate = useNavigate()
  const {
    watch,
    register,
    resetField,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useFormContext<FormValues>()
  const analytics = useAnalytics()
  const { t, ready } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [dateFormat, setDateFormat] = useState('MM/DD/YYYY')
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const formValues = getValues()
  const { useRedirectIsLoading } = useRedirect()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const termsUrl = t('AccountRegistration.formContent.termsUrl')
  const policiesUrl = t('AccountRegistration.formContent.policiesUrl')

  const onSubmit: SubmitHandler<FieldValues> = () => {
    analytics.sendEvent(
      LocalAnalyticsEvent.ContinueNextStep,
      EventCategory.Button,
      EventAction.Click
    )
    analytics.pushToDatalayer({
      event: 'registration_flow',
      form_name: 'Instructor Signup Form',
      step_label: 'Continue to next step',
    })
    navigate(CreateAccountPaths.Second)
  }
  useEffect(() => {
    if (ready && !useRedirectIsLoading) {
      analytics.sendPageView(LocalAnalyticsEvent.FirstPageView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useRedirectIsLoading, ready])

  const watchCountry = watch('country')

  useEffect(() => {
    setDateFormat(
      watchCountry === CountryEnum.UnitedStates ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
    )
  }, [watchCountry])

  const resetStateVal = (country: string) => {
    return (
      country !== CountryEnum.UnitedStates ? resetField('state') : null,
      country === CountryEnum.Germany
        ? setValue('isOptInCorrespondence', false, { shouldValidate: true })
        : setValue('isOptInCorrespondence', true, { shouldValidate: true }),
      country === CountryEnum.UnitedStates &&
        setValue('state', StatesEnum.Alabama, { shouldValidate: true })
    )
  }

  const handleNavigateBack = () => {
    analytics.sendEvent(
      LocalAnalyticsEvent.FindTraining,
      EventCategory.Button,
      EventAction.Click
    )
    return (window.location.href = import.meta.env.REACT_APP_FIND_TRAINING_URL!)
  }

  const handleFindOutMore = (e: React.MouseEvent) => {
    e.preventDefault()
    isSmallScreen
      ? (window.location.href = import.meta.env.REACT_APP_CONTACT_US_URL!)
      : window.open(import.meta.env.REACT_APP_CONTACT_US_URL!, '_blank')
  }

  const openInNewTab = isSmallScreen
    ? { target: '_self' }
    : {
        target: '_blank',
        rel: 'noopener noreferrer',
      }

  if (!ready || useRedirectIsLoading) {
    return (
      <Container elevation={4}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </Container>
    )
  }

  return (
    <Container elevation={4}>
      <FormContainer $isClosed={isOpen} onClick={() => setIsOpen(false)}>
        <NavigationContainer $hiddenWhenSmall display="flex" mb={5}>
          <Button
            variant="text"
            startIcon={<BackChevron />}
            onClick={handleNavigateBack}
          >
            <Typography variant="body1" color="textSecondary">
              {t('AccountRegistration.navigation.findTraining')}
            </Typography>
          </Button>
        </NavigationContainer>
        <Heading variant="h6">
          {t('AccountRegistration.formContent.createAccount')}
        </Heading>
        {((touchedFields.email && errors.email?.message) ||
          errors.firstName?.message ||
          errors.lastName?.message ||
          (touchedFields.dateOfBirth && errors.dateOfBirth?.message)) && (
          <ErrorDisplay
            message={
              (errors.email?.message ||
                errors.firstName?.message ||
                errors.lastName?.message ||
                errors.dateOfBirth?.message) as string
            }
          />
        )}

        <TextInput
          {...register('email', {
            required: {
              value: true,
              message: t(
                'AccountRegistration.formContent.feedback.emailRequired'
              ),
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t(
                'AccountRegistration.formContent.feedback.invalidEmail'
              ),
            },
          })}
          error={!!errors.email && touchedFields.email}
          color="secondary"
          variant="outlined"
          placeholder={t('AccountRegistration.formContent.email')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutline />
              </InputAdornment>
            ),
          }}
        />
        <NameInputContainer>
          <TextInput
            {...register('firstName', {
              required: {
                value: true,
                message: t(
                  'AccountRegistration.formContent.feedback.firstNameRequired'
                ),
              },
            })}
            error={!!errors.firstName}
            color="secondary"
            variant="outlined"
            placeholder={t('AccountRegistration.formContent.firstName')}
            mr={0.5}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentity />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            {...register('lastName', {
              required: {
                value: true,
                message: t(
                  'AccountRegistration.formContent.feedback.lastNameRequired'
                ),
              },
            })}
            error={!!errors.lastName}
            color="secondary"
            variant="outlined"
            placeholder={t('AccountRegistration.formContent.lastName')}
            ml={0.5}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentity />
                </InputAdornment>
              ),
            }}
          />
        </NameInputContainer>
        <Controller
          name="country"
          control={control}
          render={({ field }) => {
            return (
              <StyledSelect
                {...field}
                variant="outlined"
                IconComponent={KeyboardArrowDown}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                {countries.map((country: CountryItemShape) => (
                  <MenuItem
                    key={country.value}
                    value={country.value as CountryEnum}
                    onClick={() => resetStateVal(country.value)}
                  >
                    <ListItemIcon>
                      <FlagIcon code={country.flag} size={20} />
                    </ListItemIcon>
                    {t(`AccountRegistration.countries.${country.label}`)}
                  </MenuItem>
                ))}
                <StyledMenuItem onClick={handleFindOutMore}>
                  <Typography>
                    {t('AccountRegistration.formContent.noCountry')}
                  </Typography>
                  <GoldText>
                    {t('AccountRegistration.formContent.findOutMore')}
                  </GoldText>
                </StyledMenuItem>
              </StyledSelect>
            )
          }}
        />
        {watchCountry && formValues.country === CountryEnum.UnitedStates && (
          <Controller
            name="state"
            control={control}
            defaultValue={formValues.state || StatesEnum.Alabama}
            render={({ field }) => {
              return (
                <StyledSelect
                  {...field}
                  variant="outlined"
                  IconComponent={KeyboardArrowDown}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  defaultValue={StatesEnum.Alabama}
                >
                  {states.map((state: StateItemShape) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )
            }}
          />
        )}
        <DateOfBirthInputContainer>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              validate: {
                dobValidation: value => {
                  const momentValue = moment(value)
                  const validationResult =
                    !value || // if value is empty, don't validate, as it is optional field
                    (momentValue.isValid() &&
                      moment() > momentValue &&
                      moment('1900-01-01') < momentValue)
                  return (
                    validationResult ||
                    t(
                      'AccountRegistration.formContent.feedback.invalidDateOfBirth'
                    ).toString() // toString() to pass type check
                  )
                },
              },
            }}
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  InputProps={{
                    error: !!touchedFields.dateOfBirth && !!errors.dateOfBirth,
                    placeholder: t(
                      'AccountRegistration.formContent.dateOfBirth'
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  format={dateFormat}
                  formatDensity="spacious"
                  variant="outlined"
                  color="secondary"
                  sx={DateOfBirthInputStyle}
                />
              )
            }}
          />
          <Tooltip
            title={t('AccountRegistration.formContent.dateOfBirthInfo')}
            arrow
            placement="top"
            enterTouchDelay={0} // bring touch event work on touch screen
          >
            <InfoOutlined />
          </Tooltip>
        </DateOfBirthInputContainer>
        {watchCountry && formValues.country === CountryEnum.UnitedKingdom && (
          <QualificationContainer>
            <CheckboxContainer>
              <Controller
                name="hasQualification"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      checked={value}
                      handleClick={() => onChange(!value)}
                      mr={2}
                      flexShrink={0}
                      width="24px"
                    />
                  )
                }}
              />
              <Typography variant="body1">
                {t('AccountRegistration.formContent.hasQualification')}
              </Typography>
            </CheckboxContainer>
          </QualificationContainer>
        )}
        <MarketingContainer>
          <CheckboxContainer>
            <Controller
              name="isOptInCorrespondence"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    data-testid="checkbox-isOptInCorrespondence"
                    checked={value}
                    handleClick={() => onChange(!value)}
                    mr={2}
                    flexShrink={0}
                    width="24px"
                  />
                )
              }}
            />
            <Typography variant="body1">
              {t('AccountRegistration.formContent.marketingSignup')}
            </Typography>
          </CheckboxContainer>
          <TextWithStyledLinks variant="body1">
            <Trans
              i18nKey={'AccountRegistration.formContent.policiesAndTerms'}
              components={[
                <a href={termsUrl} {...openInNewTab} />,
                <a href={policiesUrl} {...openInNewTab} />,
              ]}
            />
          </TextWithStyledLinks>
        </MarketingContainer>
        <StyledButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          {t('AccountRegistration.formContent.nextButton')}
        </StyledButton>
        <FormFooter />
      </FormContainer>
      <SidebarContainer $isOpen={isOpen}>
        <SidebarContent isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
      </SidebarContainer>
    </Container>
  )
}

export default FirstPage
