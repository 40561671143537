import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material/'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Button from 'src/components/button/Button'
import ErrorDisplay from 'src/components/feedback/ErrorDisplay'
import PhoneNumberSelect from 'src/components/inputs/PhoneNumberSelect'
import FormFooter from 'src/modules/CreateAccount/FormFooter'
import SidebarContent from 'src/modules/CreateAccount/SidebarContent'

import countries, { CountryItemShape } from 'src/utils/countries'
import { CreateAccountPaths } from 'src/AppRouter'
import useToast from 'src/hooks/useToast'
import { isErrorResult } from 'src/utils/typeGuards'
import useAnalytics from 'src/hooks/useAnalytics'
import { LocalAnalyticsEvent } from 'src/providers/AnalyticsProvider'

import {
  NavigationContainer,
  BackChevron,
  Heading,
  StyledButton,
  Subheading,
  Container,
  FormContainer,
  SidebarContainer,
} from 'src/modules/CreateAccount/FormContent.styles'

import { useCreateAffiliationMutation } from 'src/generated/hooks'
import { useParams } from 'src/providers/ParamProvider'
import Toast, { ToastTypeEnum } from 'src/components/feedback/Toast'
import { FormValues } from 'src/providers'
import moment from 'moment'

const SecondPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [createAccount, { loading }] = useCreateAffiliationMutation()
  const { showToast, hideToast, toastState, isToastOpen } = useToast()
  const analytics = useAnalytics()
  const { eventId } = useParams() || {}

  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useFormContext<FormValues>()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit: SubmitHandler<FormValues> = async (formData: FormValues) => {
    analytics.pushToDatalayer({
      event: 'registration_flow',
      form_name: 'Instructor Signup Form',
      step_label: 'Complete Signup',
    })
    if (!executeRecaptcha) {
      throw new Error(t('AccountRegistration.formContent.feedback.errorToast'))
    }
    const token = await executeRecaptcha('setup_account')
    const country = countries.find(item => item.value === formData.countryCode)
    const data = {
      country: formData.country,
      email: formData.email,
      firstName: formData.firstName,
      isOptInCorrespondence: formData.isOptInCorrespondence,
      lastName: formData.lastName,
      phone: `${country?.countryCode}${formData.phoneNumber}`,
      recaptchaToken: token,
      state: formData.state,
      dateOfBirth: formData.dateOfBirth
        ? moment(formData.dateOfBirth).format('YYYY-MM-DD')
        : undefined,
      hasPreReqFitnessQualification: formData.hasQualification
        ? true
        : undefined, // there is no option to select false
      eventId: eventId,
    }

    sessionStorage.setItem('email', formData.email)

    try {
      const response = await createAccount({
        variables: { input: data },
      })
      if (
        isErrorResult(response?.data?.createInstructorAccount) ||
        !response.data
      ) {
        throw new Error(
          t('AccountRegistration.formContent.feedback.errorToast')
        )
      }
      navigate(CreateAccountPaths.Third)
    } catch (err) {
      showToast({
        message: t('AccountRegistration.formContent.feedback.errorToast'),
        variant: ToastTypeEnum.ERROR,
      })
    }
  }

  const onPrevPage = () => navigate(CreateAccountPaths.First)

  useEffect(() => {
    analytics.sendPageView(LocalAnalyticsEvent.SecondPageView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formValues = getValues()

  const selectedCountryValue = countries.find(
    (item: CountryItemShape) => item.value === formValues.country
  )

  useEffect(() => {
    const { firstName, lastName, email, country } = formValues
    if (!firstName || !lastName || !email || !country) {
      return navigate(CreateAccountPaths.First)
    }
  }, [formValues, navigate])

  return (
    <>
      <Toast
        isOpen={isToastOpen}
        handleClose={hideToast}
        message={toastState?.message}
        variant={toastState?.variant}
      />
      <Container elevation={4}>
        <FormContainer $isClosed={isOpen} onClick={() => setIsOpen(false)}>
          <Box display="flex" flexDirection="column" height="100%">
            <NavigationContainer display="flex" mb={5}>
              <Button
                variant="text"
                startIcon={<BackChevron />}
                onClick={onPrevPage}
              >
                <Typography variant="body1" color="textSecondary">
                  {t('AccountRegistration.navigation.back')}
                </Typography>
              </Button>
            </NavigationContainer>
            <Heading variant="h6">
              {t('AccountRegistration.formContent.addPhone')}
            </Heading>
            {errors.phoneNumber?.message && (
              <ErrorDisplay message={errors.phoneNumber?.message} />
            )}
            <Subheading variant="body1">
              {t('AccountRegistration.formContent.addPhoneDescription')}
            </Subheading>
            <PhoneNumberSelect
              options={countries}
              register={register}
              phoneNumberError={errors.phoneNumber}
              selectedCountryValue={selectedCountryValue || countries[0]}
            />
            <StyledButton
              type="submit"
              disabled={!isValid || loading}
              onClick={handleSubmit(onSubmit)}
              loading={loading}
            >
              {t('AccountRegistration.formContent.complete')}
            </StyledButton>
            <FormFooter />
          </Box>
        </FormContainer>
        <SidebarContainer $isOpen={isOpen}>
          <SidebarContent isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
        </SidebarContainer>
      </Container>
    </>
  )
}

export default SecondPage
