import {
  createContext,
  PropsWithChildren,
  useEffect,
  useContext,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

export type ParamData = {
  eventId?: string | null
  countrySearchParams?: string | null
  iss?: string | null
}

export const ParamContext = createContext<ParamData | null>(null)

export const ParamProvider = ({ children }: PropsWithChildren<{}>) => {
  const [searchParams] = useSearchParams()
  const [paramValues, setParamValues] = useState<ParamData>({})

  useEffect(() => {
    const eventId =
      searchParams.get('eventId') || localStorage.getItem('eventId')
    const sanitisedEventId = eventId?.includes('http')
      ? eventId.split('/').pop()
      : eventId

    setParamValues({
      eventId: sanitisedEventId,
      countrySearchParams:
        searchParams.get('country') || localStorage.getItem('country'),
      iss: searchParams.get('iss'),
    })
  }, [searchParams])

  useEffect(() => {
    const { countrySearchParams, eventId, iss } = paramValues
    eventId && localStorage.setItem('eventId', eventId)
    countrySearchParams && localStorage.setItem('country', countrySearchParams)
    iss && localStorage.setItem('iss', iss)
  }, [paramValues])

  return (
    <ParamContext.Provider value={paramValues}>
      {children}
    </ParamContext.Provider>
  )
}

export const useParams = () => useContext(ParamContext)
