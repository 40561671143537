import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum CountryEnum {
  Armenia = 'ARMENIA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Belarus = 'BELARUS',
  Bermuda = 'BERMUDA',
  Brazil = 'BRAZIL',
  Bulgaria = 'BULGARIA',
  CarribeanIslands = 'CARRIBEAN_ISLANDS',
  China = 'CHINA',
  Czechia = 'CZECHIA',
  Denmark = 'DENMARK',
  Estonia = 'ESTONIA',
  Finland = 'FINLAND',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  India = 'INDIA',
  Iran = 'IRAN',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  Japan = 'JAPAN',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kuwait = 'KUWAIT',
  Kyrgyzstan = 'KYRGYZSTAN',
  Latvia = 'LATVIA',
  Lithuania = 'LITHUANIA',
  Moldova = 'MOLDOVA',
  Nigeria = 'NIGERIA',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Poland = 'POLAND',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  Romania = 'ROMANIA',
  Russia = 'RUSSIA',
  SaudiArabia = 'SAUDI_ARABIA',
  Slovakia = 'SLOVAKIA',
  SouthAfrica = 'SOUTH_AFRICA',
  StLucia = 'ST_LUCIA',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Syria = 'SYRIA',
  Tajikistan = 'TAJIKISTAN',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
  UsVirginIslands = 'US_VIRGIN_ISLANDS',
  Uzbekistan = 'UZBEKISTAN',
  Zambia = 'ZAMBIA'
}

export type CreateInstructorAccountInput = {
  country?: Maybe<CountryEnum>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPreReqFitnessQualification?: Maybe<Scalars['Boolean']>;
  isOptInCorrespondence?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recaptchaToken?: Maybe<Scalars['String']>;
  state?: Maybe<StatesEnum>;
};

export type CreateInstructorAccountResponse = CreateInstructorAccountResult | ErrorResult;

export type CreateInstructorAccountResult = {
  __typename?: 'CreateInstructorAccountResult';
  success?: Maybe<Scalars['Boolean']>;
};

export enum Error {
  InvalidRequest = 'INVALID_REQUEST',
  ServerError = 'SERVER_ERROR',
  SessionNotFound = 'SESSION_NOT_FOUND'
}

export type ErrorResult = {
  __typename?: 'ErrorResult';
  component?: Maybe<Scalars['String']>;
  logGroup?: Maybe<Scalars['String']>;
  logStream?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  type: Error;
};

export type GetUserSessionResponse = ErrorResult | GetUserSessionResult;

export type GetUserSessionResult = {
  __typename?: 'GetUserSessionResult';
  country?: Maybe<CountryEnum>;
  eventId: Scalars['String'];
  market?: Maybe<Scalars['String']>;
  state?: Maybe<StatesEnum>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _root?: Maybe<Scalars['String']>;
  createInstructorAccount: CreateInstructorAccountResponse;
  resendPasswordResetEmail: ResendPasswordResetEmailResponse;
};


export type Mutation_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateInstructorAccountArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
  input: CreateInstructorAccountInput;
};


export type MutationResendPasswordResetEmailArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
  input: ResendPasswordResetEmailInput;
};

export type Query = {
  __typename?: 'Query';
  _root?: Maybe<Scalars['String']>;
  getUserSession: GetUserSessionResponse;
};


export type Query_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetUserSessionArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};

export type ResendPasswordResetEmailInput = {
  email: Scalars['String'];
};

export type ResendPasswordResetEmailResponse = ErrorResult | ResendPasswordResetEmailResult;

export type ResendPasswordResetEmailResult = {
  __typename?: 'ResendPasswordResetEmailResult';
  success?: Maybe<Scalars['Boolean']>;
};

export enum StatesEnum {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING'
}

export type Subscription = {
  __typename?: 'Subscription';
  _root?: Maybe<Scalars['String']>;
};

export type CreateAffiliationMutationVariables = Exact<{
  input: CreateInstructorAccountInput;
}>;


export type CreateAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { createInstructorAccount: (
    { __typename?: 'CreateInstructorAccountResult' }
    & Pick<CreateInstructorAccountResult, 'success'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message' | 'requestId' | 'logGroup' | 'logStream'>
  ) }
);

export type GetUserSessionQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetUserSessionQuery = (
  { __typename?: 'Query' }
  & { getUserSession: (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message' | 'requestId' | 'logGroup' | 'logStream'>
  ) | (
    { __typename?: 'GetUserSessionResult' }
    & Pick<GetUserSessionResult, 'country' | 'eventId' | 'market' | 'state'>
  ) }
);

export type ResendPasswordResetEmailMutationVariables = Exact<{
  input: ResendPasswordResetEmailInput;
}>;


export type ResendPasswordResetEmailMutation = (
  { __typename?: 'Mutation' }
  & { resendPasswordResetEmail: (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message' | 'requestId' | 'logGroup' | 'logStream'>
  ) | (
    { __typename?: 'ResendPasswordResetEmailResult' }
    & Pick<ResendPasswordResetEmailResult, 'success'>
  ) }
);


export const CreateAffiliationDocument = gql`
    mutation createAffiliation($input: CreateInstructorAccountInput!) {
  createInstructorAccount(input: $input) {
    ... on CreateInstructorAccountResult {
      success
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;
export type CreateAffiliationMutationFn = Apollo.MutationFunction<CreateAffiliationMutation, CreateAffiliationMutationVariables>;

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAffiliationMutation, CreateAffiliationMutationVariables>) {
        return Apollo.useMutation<CreateAffiliationMutation, CreateAffiliationMutationVariables>(CreateAffiliationDocument, baseOptions);
      }
export type CreateAffiliationMutationHookResult = ReturnType<typeof useCreateAffiliationMutation>;
export type CreateAffiliationMutationResult = Apollo.MutationResult<CreateAffiliationMutation>;
export type CreateAffiliationMutationOptions = Apollo.BaseMutationOptions<CreateAffiliationMutation, CreateAffiliationMutationVariables>;
export const GetUserSessionDocument = gql`
    query getUserSession($_cacheKey: String) {
  getUserSession(_cacheKey: $_cacheKey) {
    ... on GetUserSessionResult {
      country
      eventId
      market
      state
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;

/**
 * __useGetUserSessionQuery__
 *
 * To run a query within a React component, call `useGetUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
        return Apollo.useQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
      }
export function useGetUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSessionQuery, GetUserSessionQueryVariables>) {
          return Apollo.useLazyQuery<GetUserSessionQuery, GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
        }
export type GetUserSessionQueryHookResult = ReturnType<typeof useGetUserSessionQuery>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionQueryResult = Apollo.QueryResult<GetUserSessionQuery, GetUserSessionQueryVariables>;
export const ResendPasswordResetEmailDocument = gql`
    mutation resendPasswordResetEmail($input: ResendPasswordResetEmailInput!) {
  resendPasswordResetEmail(input: $input) {
    ... on ResendPasswordResetEmailResult {
      success
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;
export type ResendPasswordResetEmailMutationFn = Apollo.MutationFunction<ResendPasswordResetEmailMutation, ResendPasswordResetEmailMutationVariables>;

/**
 * __useResendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useResendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPasswordResetEmailMutation, { data, loading, error }] = useResendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendPasswordResetEmailMutation, ResendPasswordResetEmailMutationVariables>) {
        return Apollo.useMutation<ResendPasswordResetEmailMutation, ResendPasswordResetEmailMutationVariables>(ResendPasswordResetEmailDocument, baseOptions);
      }
export type ResendPasswordResetEmailMutationHookResult = ReturnType<typeof useResendPasswordResetEmailMutation>;
export type ResendPasswordResetEmailMutationResult = Apollo.MutationResult<ResendPasswordResetEmailMutation>;
export type ResendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<ResendPasswordResetEmailMutation, ResendPasswordResetEmailMutationVariables>;