import styled, { css } from 'styled-components'
import {
  Box,
  Typography,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SxProps,
  Paper,
} from '@mui/material/'
import Button from 'src/components/button/Button'
import { ChevronLeft } from '@mui/icons-material/'
import { Theme } from '@mui/material/styles'

export const Heading = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `
)
export const Subheading = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `
)

export const BackChevron = styled(ChevronLeft)(
  ({ theme }) => css`
    color: ${theme.palette.brand.grayscaleBlack};
  `
)
export const NavigationContainer = styled(Box)<{ $hiddenWhenSmall?: boolean }>(
  ({ theme, $hiddenWhenSmall }) => css`
    display: flex;
    margin-bottom: ${theme.spacing(5)};

    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.spacing(2)};
      ${$hiddenWhenSmall ? `display: none;` : ''}
    }
  `
)
export const NameInputContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `
)

export const DateOfBirthInputContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};
  `
)

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;

    ${theme.breakpoints.up('md')} {
      width: 368px;
      margin: 0 auto;
    }
  `
)
export const TextWithStyledLinks = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1)};
    margin-left: ${theme.spacing(5)};

    > a {
      color: ${theme.palette.brand.gold};
    }
  `
)
export const StyledSelect = styled(MuiSelect)(
  ({ theme }) => css`
    width: 100%;
    height: 56px;
    margin-bottom: ${theme.spacing(2)};
    font-weight: ${theme.typography.fontWeightMedium};

    .MuiPopover-paper {
      margin-top: 8px;
      border-radius: 0;
      border: 1px solid ${theme.palette.brand.gray};
    }

    .MuiList-root {
      padding: 0;
    }

    .MuiListItemIcon-root {
      min-width: 35px;
    }

    .MuiInputBase-input {
      display: flex;
      align-items: center;
    }
  `
)

export const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    line-height: ${theme.typography.pxToRem(54)};
    background-color: ${theme.palette.brand.white} !important;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.brand.gray};
    }
  `
)
export const StyledMenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    line-height: ${theme.typography.pxToRem(54)};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  `
)
export const GoldText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.brand.gold};
    margin-left: ${theme.spacing(1)};
  `
)

export const Container = styled(Paper)(
  ({ theme }) =>
    css`
      width: 908px;
      height: 798px;
      margin: auto;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;

      ${theme.breakpoints.down('md')} {
        width: 100vw;
        height: 100vh;
        transform: none;
        border-radius: 0px;
        flex-direction: column-reverse;
      }
    `
)

export const FormContainer = styled(Box)<{ $isClosed?: boolean }>(
  ({ theme, $isClosed }) => css`
    width: 568px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3, 5)};
    overflow-y: auto;

    ${theme.breakpoints.down('md')} {
      width: 100%;
      padding-top: ${theme.spacing(10)};
      overflow: ${$isClosed ? 'hidden' : 'scroll'};
      min-height: fit-content;

      ::-webkit-scrollbar {
        display: none;
      }

      min-height: 100vh;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      ${$isClosed
        ? `::before {
            content:"";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;
            background-color: rgba(0, 0, 0, 0.5);}`
        : ''}
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(10, 2, 2, 2)};
    }
  `
)

export const CheckboxContainer = styled(Box)`
  display: flex;
  align-items: center;
`

export const QualificationContainer = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `
)

export const MarketingContainer = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(4)};
    min-height: fit-content;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `
)

export const SidebarContainer = styled(Box)<{ $isOpen: boolean }>(
  ({ theme, $isOpen }) => css`
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 100%;
    padding: ${theme.spacing(5)};
    background-color: ${theme.palette.brand.offWhite};

    ${theme.breakpoints.down('md')} {
      position: fixed;
      top: 0;
      width: 100%;
      overflow: hidden;
      transition: height 0.5s;
      height: ${$isOpen ? '60%' : '65px'};
      padding: ${theme.spacing(2, 5, 2, 5)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)};
      height: ${$isOpen ? '65%' : '65px'};
    }
  `
)

export const WarningContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
    border-radius: ${theme.spacing(1.25)};
    background-color: ${theme.palette.brand.offWhite};
  `
)

export const DateOfBirthInputStyle: SxProps<Theme> = {
  width: {
    sm: '100%',
    xs: '100%',
    md: '50%',
  },
  mr: 1,
}
