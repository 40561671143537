import React, { useRef, useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
  Grid,
  Select as MuiSelect,
  ListItemIcon,
  TextField,
  Box,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import { FieldError, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FlagIcon } from 'react-flag-kit'
import { CountryItemShape } from 'src/utils/countries'
import { MenuItem } from 'src/modules/CreateAccount/FormContent.styles'
import { FormValues } from 'src/providers'

const Select = styled(MuiSelect)<{ $menuWidth?: number }>(
  ({ $menuWidth }) => css`
    margin-bottom: 0;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiPaper-root {
      width: ${$menuWidth}px;
    }
  `
)

const Container = styled(Grid)<{ $error?: boolean }>(
  ({ theme, $error }) => css`
    border: 1px solid
      ${$error ? theme.palette.brand.red : theme.palette.brand.gray};
    display: flex;
    margin-bottom: ${theme.spacing(2)};
  `
)

const TextInput = styled(TextField)`
  flex-direction: unset;
  height: 100%;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const FlagIconWithRightMargin = styled(FlagIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)};
  `
)

export interface SelectProps extends Omit<MuiSelectProps, 'value' | 'options'> {
  options: CountryItemShape[]
  selectedCountryValue: CountryItemShape
  register: UseFormRegister<FormValues>
  phoneNumberError?: FieldError
}

const PhoneNumberSelect: React.FC<SelectProps> = ({
  options,
  selectedCountryValue,
  phoneNumberError,
  register,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const id = useRef(`${Date.now()}-${Math.ceil(Math.random() * 10000)}`)
  const { t } = useTranslation()
  const containerWidth = containerRef.current?.offsetWidth

  const [isFocused, setIsFocused] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [componentIsLoaded, setComponentIsLoaded] = useState(false)

  const handleClick = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    setIsFocused(true)
  }

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isFocused])

  // This useEffect ensures that the containerRef has loaded
  useEffect(() => {
    setComponentIsLoaded(true)
  }, [])

  const MemoizedSelect = useMemo(() => {
    return (
      <Select
        {...register('countryCode', { required: true })}
        onClick={() => setIsFocused(false)}
        fullWidth
        $menuWidth={containerWidth}
        variant="outlined"
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal: true,
          anchorEl: () => document.getElementById(id.current)!,
        }}
        defaultValue={selectedCountryValue.value}
        renderValue={value => {
          const valueObject = options.find(
            (item: CountryItemShape) => item.value === value
          )
          return (
            <Box
              display="flex"
              alignItems="center"
              data-testid="phone-countryCode"
            >
              <FlagIconWithRightMargin
                code={valueObject?.flag || selectedCountryValue.flag}
                size={20}
              />
              {`(+${
                valueObject?.countryCode || selectedCountryValue?.countryCode
              })`}
            </Box>
          )
        }}
      >
        {options &&
          options?.map((country: CountryItemShape) => (
            <MenuItem
              key={country.value}
              value={country.value}
              onClick={handleClick}
            >
              <ListItemIcon>
                <FlagIcon code={country.flag} size={20} />
              </ListItemIcon>
              {t(`AccountRegistration.countries.${country.label}`)}{' '}
              {`(+${country.countryCode})`}
            </MenuItem>
          ))}
      </Select>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, options, selectedCountryValue])

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="flex-end"
    >
      <Container
        container
        {...{ ref: containerRef }}
        id={id.current}
        $error={!!phoneNumberError}
      >
        <Grid item xs={6} sm={4}>
          {MemoizedSelect}
        </Grid>
        <Grid item xs={6} sm={8}>
          <TextInput
            {...register('phoneNumber', {
              required: {
                value: true,
                message: t(
                  'AccountRegistration.formContent.feedback.phoneNumberRequired'
                ),
              },
              pattern: {
                value: /^[\d\s-]+$/,
                message: t(
                  'AccountRegistration.formContent.feedback.invalidPhoneNumber'
                ),
              },
            })}
            error={!!phoneNumberError}
            inputRef={inputRef}
            inputProps={{
              maxLength: 20,
            }}
          />
        </Grid>
      </Container>
    </Box>
  )
}

export default PhoneNumberSelect
