import styled, { css } from 'styled-components'

import { Divider as MuiDivider } from '@mui/material'

const Divider = styled(MuiDivider)<{
  m?: number
  mt?: number
  mb?: number
  my?: number
}>(
  ({ theme, m, mt, mb, my }) => css`
    ${m !== undefined ? `margin: ${theme.spacing(m)};` : ''}
    ${my !== undefined ? `margin-top: ${theme.spacing(my)};` : ''}
    ${my !== undefined ? `margin-bottom: ${theme.spacing(my)};` : ''}
    ${mt !== undefined ? `margin-top: ${theme.spacing(mt)};` : ''}
    ${mb !== undefined ? `margin-bottom: ${theme.spacing(mb)};` : ''}
    width: 100%;
  `
)

export default Divider
