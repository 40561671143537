import React, { useMemo } from 'react'
import i18next from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import moment from 'moment'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const acceptedLanguages = [
  'en-GB',
  'en-US',
  'en-PH',
  'de-DE',
  'en-UK',
  'ru-RU',
  'ja-JP',
  'zh-CN',
]
const I18nProvider: React.FC = ({ children }) => {
  const replaceBlankSpaceWithUnicode = (suffix: string) => {
    const [, commaWithSpace] = suffix.split('"')
    const isCommaWithSpace = /\s/g.test(suffix)
    const currentSuffix =
      !!commaWithSpace && !!isCommaWithSpace
        ? commaWithSpace.replace(/\s/g, '\u0020') // replace blank space with unicode
        : suffix
    return currentSuffix
  }

  useMemo(
    () =>
      i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(Backend)
        .init({
          detection: {
            order: ['navigator'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            caches: ['localStorage', 'cookie'],
          },
          nonExplicitSupportedLngs: true,
          backend: {
            loadPath: props => {
              let lng = props[0]
              if (!acceptedLanguages.includes(props[0])) {
                const lngPrefix = props[0].slice(0, 2)
                const replacementLanguage = acceptedLanguages.find(
                  (lngItem: string) => lngItem.includes(lngPrefix)
                )
                lng = replacementLanguage || props[0]
              }
              return `${
                import.meta.env.REACT_APP_TRANSLATION_URL
              }${lng.toLowerCase()}.json`
            },
          },
          load: 'currentOnly', // we don't want to load en when we set en-GB
          fallbackLng: 'en-US',
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
              if (
                (typeof value === 'string' && value.trim().length === 0) ||
                value === null ||
                value === undefined
              ) {
                return ''
              }
              if (format?.startsWith('suffix')) {
                const [, suffix] = format.split(':')
                const currentSuffix = replaceBlankSpaceWithUnicode(suffix)
                return `${value}${currentSuffix}`
              }
              if (format?.startsWith('prefix')) {
                const [, prefix] = format.split(':')
                return `${prefix}${value}`
              }
              if (value instanceof Date) {
                return moment(value)
                  .locale(lng || i18next.language)
                  .format(format)
              }
              return value
            },
          },
          react: {
            useSuspense: false,
          },
        }),
    []
  )

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}

export default I18nProvider
