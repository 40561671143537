import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export interface ImgProps {
  src: string
  alt: string
  role?: string
  width?: number
  height?: number
}

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`

const Img: React.FC<ImgProps> = props => <Image loading="lazy" {...props} />

const Square = styled(Box)`
  width: 100%;
  position: relative;
  padding-bottom: 100%;
`
const SquareImage = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
`
interface SquareImageProps extends ImgProps {}
export const SquareImg: React.FC<SquareImageProps> = ({ width, ...props }) => (
  <Square width={width}>
    <SquareImage {...props} />
  </Square>
)

export default Img
