import { StatesEnum } from 'src/generated/graphql'

export interface StateItemShape {
  label: string
  value: string
}

export const states: StateItemShape[] = [
  { label: 'Alabama', value: StatesEnum.Alabama },
  { label: 'Alaska', value: StatesEnum.Alaska },
  { label: 'Arizona', value: StatesEnum.Arizona },
  { label: 'Arkansas', value: StatesEnum.Arkansas },
  { label: 'California', value: StatesEnum.California },
  { label: 'Colorado', value: StatesEnum.Colorado },
  { label: 'Connecticut', value: StatesEnum.Connecticut },
  { label: 'Delaware', value: StatesEnum.Delaware },
  { label: 'Florida', value: StatesEnum.Florida },
  { label: 'Georgia', value: StatesEnum.Georgia },
  { label: 'Hawaii', value: StatesEnum.Hawaii },
  { label: 'Idaho', value: StatesEnum.Idaho },
  { label: 'Illinois', value: StatesEnum.Illinois },
  { label: 'Indiana', value: StatesEnum.Indiana },
  { label: 'Iowa', value: StatesEnum.Iowa },
  { label: 'Kansas', value: StatesEnum.Kansas },
  { label: 'Kentucky', value: StatesEnum.Kentucky },
  { label: 'Louisiana', value: StatesEnum.Louisiana },
  { label: 'Maine', value: StatesEnum.Maine },
  { label: 'Maryland', value: StatesEnum.Maryland },
  { label: 'Massachusetts', value: StatesEnum.Massachusetts },
  { label: 'Michigan', value: StatesEnum.Michigan },
  { label: 'Minnesota', value: StatesEnum.Minnesota },
  { label: 'Mississippi', value: StatesEnum.Mississippi },
  { label: 'Missouri', value: StatesEnum.Missouri },
  { label: 'Montana', value: StatesEnum.Montana },
  { label: 'Nebraska', value: StatesEnum.Nebraska },
  { label: 'Nevada', value: StatesEnum.Nevada },
  { label: 'New Hampshire', value: StatesEnum.NewHampshire },
  { label: 'New Jersey', value: StatesEnum.NewJersey },
  { label: 'New Mexico', value: StatesEnum.NewMexico },
  { label: 'New York', value: StatesEnum.NewYork },
  { label: 'North Carolina', value: StatesEnum.NorthCarolina },
  { label: 'North Dakota', value: StatesEnum.NorthDakota },
  { label: 'Ohio', value: StatesEnum.Ohio },
  { label: 'Oklahoma', value: StatesEnum.Oklahoma },
  { label: 'Oregon', value: StatesEnum.Oregon },
  { label: 'Pennsylvania', value: StatesEnum.Pennsylvania },
  { label: 'Rhode Island', value: StatesEnum.RhodeIsland },
  { label: 'South Carolina', value: StatesEnum.SouthCarolina },
  { label: 'South Dakota', value: StatesEnum.SouthDakota },
  { label: 'Tennessee', value: StatesEnum.Tennessee },
  { label: 'Texas', value: StatesEnum.Texas },
  { label: 'Utah', value: StatesEnum.Utah },
  { label: 'Vermont', value: StatesEnum.Vermont },
  { label: 'Virginia', value: StatesEnum.Virginia },
  { label: 'Washington', value: StatesEnum.Washington },
  { label: 'Washington D.C', value: StatesEnum.DistrictOfColumbia },
  { label: 'West Virginia', value: StatesEnum.WestVirginia },
  { label: 'Wisconsin', value: StatesEnum.Wisconsin },
  { label: 'Wyoming', value: StatesEnum.Wyoming },
]

export default states
