import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useGetUserSessionLazyQuery } from 'src/generated/hooks'
import { isErrorResult } from 'src/utils/typeGuards'
import { useParams } from 'src/providers/ParamProvider'

const liveMarkets = import.meta.env.REACT_APP_LIVE_MARKETS?.split(',') || []
const LMJP = 'LMJP'

const useRedirect = () => {
  const { user, loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const { iss, eventId: sessionEventId } = useParams() || {}
  const [getUserSession, { data, loading }] = useGetUserSessionLazyQuery()
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setIsRedirecting(true)
      getUserSession()
    }
  }, [isAuthenticated, getUserSession])

  useEffect(() => {
    if (iss && !isAuthenticated) {
      loginWithRedirect({
        redirectUri: window.location.origin,
      })
    }
  }, [iss, loginWithRedirect, user, isLoading, isAuthenticated])

  const getRedirectUrl = ({ market = '', eventId }: any): string => {
    const isLMJP = market === LMJP
    const isLiveMarket = liveMarkets.includes(market)

    const ixhubEventUrl = import.meta.env.REACT_APP_EVENT_URL
    const ixhubUrl = import.meta.env.REACT_APP_INSTRUCTOR_PORTAL_EVENT_GENERIC

    const connectEventUrl = import.meta.env.REACT_APP_CONNECT_EVENT_URL
    const connectUrl = import.meta.env.REACT_APP_CONNECT_URL

    const santisedEventId = eventId?.includes('http')
      ? eventId.split('/').pop()
      : eventId

    // Redirect to connectUrl if the market is LMJP, or there's no eventId and the market is a live market
    if (isLMJP || (!santisedEventId && isLiveMarket)) {
      return connectUrl as string
    }

    // Logic for when there is an eventId
    if (santisedEventId) {
      return `${
        isLiveMarket ? connectEventUrl : ixhubEventUrl
      }${santisedEventId}`
    }

    // Default case if none of the above conditions are met
    return ixhubUrl as string
  }

  const redirector = useCallback(() => {
    if (data && !isErrorResult(data.getUserSession) && isAuthenticated) {
      const { eventId, market } = data.getUserSession || {}

      window.location.href = getRedirectUrl({ eventId, market })

      return
    }

    if (isErrorResult(data?.getUserSession)) {
      const auth0Market = user?.['https://lesmills.com/digitalhub.market']

      window.location.href = getRedirectUrl({
        market: auth0Market,
        eventId: sessionEventId,
      })
    }
  }, [data, isAuthenticated, user, sessionEventId])

  const useRedirectIsLoading = loading || !!iss || isLoading || isRedirecting

  redirector()

  return {
    useRedirectIsLoading,
  }
}

export default useRedirect
