import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const RecaptchaProvider: React.FC = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={import.meta.env.REACT_APP_RECAPTCHA_KEY}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

export default RecaptchaProvider
