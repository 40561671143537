import * as Types from 'src/generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const CreateAffiliationDocument = gql`
    mutation createAffiliation($input: CreateInstructorAccountInput!) {
  createInstructorAccount(input: $input) {
    ... on CreateInstructorAccountResult {
      success
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;
export type CreateAffiliationMutationFn = Apollo.MutationFunction<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>;

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>) {
        return Apollo.useMutation<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>(CreateAffiliationDocument, baseOptions);
      }
export type CreateAffiliationMutationHookResult = ReturnType<typeof useCreateAffiliationMutation>;
export type CreateAffiliationMutationResult = Apollo.MutationResult<Types.CreateAffiliationMutation>;
export type CreateAffiliationMutationOptions = Apollo.BaseMutationOptions<Types.CreateAffiliationMutation, Types.CreateAffiliationMutationVariables>;
export const GetUserSessionDocument = gql`
    query getUserSession($_cacheKey: String) {
  getUserSession(_cacheKey: $_cacheKey) {
    ... on GetUserSessionResult {
      country
      eventId
      market
      state
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;

/**
 * __useGetUserSessionQuery__
 *
 * To run a query within a React component, call `useGetUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionQuery({
 *   variables: {
 *      _cacheKey: // value for '_cacheKey'
 *   },
 * });
 */
export function useGetUserSessionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserSessionQuery, Types.GetUserSessionQueryVariables>) {
        return Apollo.useQuery<Types.GetUserSessionQuery, Types.GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
      }
export function useGetUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserSessionQuery, Types.GetUserSessionQueryVariables>) {
          return Apollo.useLazyQuery<Types.GetUserSessionQuery, Types.GetUserSessionQueryVariables>(GetUserSessionDocument, baseOptions);
        }
export type GetUserSessionQueryHookResult = ReturnType<typeof useGetUserSessionQuery>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionQueryResult = Apollo.QueryResult<Types.GetUserSessionQuery, Types.GetUserSessionQueryVariables>;
export const ResendPasswordResetEmailDocument = gql`
    mutation resendPasswordResetEmail($input: ResendPasswordResetEmailInput!) {
  resendPasswordResetEmail(input: $input) {
    ... on ResendPasswordResetEmailResult {
      success
    }
    ... on ErrorResult {
      type
      message
      requestId
      logGroup
      logStream
      __typename
    }
  }
}
    `;
export type ResendPasswordResetEmailMutationFn = Apollo.MutationFunction<Types.ResendPasswordResetEmailMutation, Types.ResendPasswordResetEmailMutationVariables>;

/**
 * __useResendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useResendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPasswordResetEmailMutation, { data, loading, error }] = useResendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResendPasswordResetEmailMutation, Types.ResendPasswordResetEmailMutationVariables>) {
        return Apollo.useMutation<Types.ResendPasswordResetEmailMutation, Types.ResendPasswordResetEmailMutationVariables>(ResendPasswordResetEmailDocument, baseOptions);
      }
export type ResendPasswordResetEmailMutationHookResult = ReturnType<typeof useResendPasswordResetEmailMutation>;
export type ResendPasswordResetEmailMutationResult = Apollo.MutationResult<Types.ResendPasswordResetEmailMutation>;
export type ResendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<Types.ResendPasswordResetEmailMutation, Types.ResendPasswordResetEmailMutationVariables>;